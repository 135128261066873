import React, {Suspense} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Main from './components/main/Main';
import {categories} from '../src/data';
import InfoProjectPage from './components/infoProjectPage/InfoProjectPage'
import ProjectsPage from './components/projectsPage/ProjectsPage'

const CatalogPage = React.lazy(() => import('./components/catalog/Catalog'))
const ServicesPage = React.lazy(() => import('./components/services/Services'))
const ContactsPage = React.lazy(() => import('./components/contacts/Contacts'))
const AboutPage = React.lazy(() => import('./components/about/About'))
const RoofPageLazy = React.lazy(() => import('./components/services/servicesPages/roofPage/RoofPage'))
const WindowPageLazy = React.lazy(() => import('./components/services/servicesPages/windowPage/WindowPage'))
const WarmingPageLazy = React.lazy(() => import('./components/services/servicesPages/warmingPage/WarmingPage'))
const TreatmentPageLazy = React.lazy(() => import('./components/services/servicesPages/treatmentPage/TreatmentPage'))
const MaterialsPageLazy = React.lazy(() => import('./components/services/servicesPages/materialsPage/MaterialsPage'))
const FoundationPageLazy = React.lazy(() => import('./components/services/servicesPages/foundationPage/FoundationPage'))
const DeliveryPageLazy = React.lazy(() => import('./components/services/servicesPages/deliveryPage/DeliveryPage'))
const AssemblyPageLazy = React.lazy(() => import('./components/services/servicesPages/assemblyPage/AssemblyPage'))
const Error404Page = React.lazy(() => import('./components/error404/Error404'))

export const PATH = {
    MAIN: '/main',
    SERVICES: '/services',
    ABOUT: '/about',
    CONTACTS: '/contacts',
    CATALOG: '/catalog',

    CATALOG_HOUSES: '/catalog/houses',
    CATALOG_GAZEBOS: '/catalog/gazebos',
    CATALOG_HOZBLOCKS: '/catalog/hozblocks',
    CATALOG_TOILETS: '/catalog/toilets',

    SERVICES_ASSEMBLY: '/services/assembly',
    SERVICES_MATERIALS: '/services/materials',
    SERVICES_ROOF: '/services/roof',
    SERVICES_FOUNDATION: '/services/foundation',
    SERVICES_DELIVERY: '/services/delivery',
    SERVICES_WINDOW: '/services/window',
    SERVICES_WARMING: '/services/warming',
    SERVICES_TREATMENT: '/services/treatment'
}

const Pages = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Navigate to={'/main'}/>}/>

            <Route path={'/main'} element={<Main/>}/>
            <Route path={'/services'} element={
                <Suspense>
                    <ServicesPage/>
                </Suspense>
            }/>
            <Route path={'/about'} element={
                <Suspense>
                    <AboutPage/>
                </Suspense>}
            />
            <Route path={'/contacts'} element={
                <Suspense>
                    <ContactsPage/>
                </Suspense>
            }/>
            <Route path={'/catalog'} element={
                <Suspense>
                    <CatalogPage/>
                </Suspense>
            }/>

            {categories.map((el) => <Route key={el.id} path={`${el.link}`} element={

                <ProjectsPage data={el.data}

                />}/>)}

            {categories.map((el) => el.data?.map((d) => <Route path={`${el.link}/${d.id}`} element={

                <InfoProjectPage key={d.id} name={d.name} description={d.description} square={d.square}
                                     dimensions={d.dimensions} layout={d.layout} firstMaterial={d.firstMaterial}
                                     secondMaterial={d.secondMaterial} thirdMaterial={d.thirdMaterial} images={d.images}
                                     plan={d.plan}

                />}/>))}

            <Route path={`services/assembly`} element={
                <Suspense>
                    <AssemblyPageLazy/>
                </Suspense>
            }/>
            <Route path={`services/materials`} element={
                <Suspense>
                    <MaterialsPageLazy/>
                </Suspense>
            }/>
            <Route path={`services/roof`} element={
                <Suspense>
                    <RoofPageLazy/>
                </Suspense>
            }/>
            <Route path={`services/foundation`} element={
                <Suspense>
                    <FoundationPageLazy/>
                </Suspense>
            }/>
            <Route path={`services/delivery`} element={
                <Suspense>
                    <DeliveryPageLazy/>
                </Suspense>
            }/>
            <Route path={`services/window`} element={
                <Suspense>
                <WindowPageLazy/>
                </Suspense>
            }/>
            <Route path={`services/warming`} element={
                <Suspense>
                <WarmingPageLazy/>
                </Suspense>
                }/>
            <Route path={`services/treatment`} element={
                <Suspense>
                <TreatmentPageLazy/>
                </Suspense>
            }/>

            <Route path={'/error404'} element={
                <Suspense>
                    <Error404Page/>
                </Suspense>
            }/>

            <Route path={'/*'} element={<Navigate to={'/error404'}/>}/>

        </Routes>
    );
};

export default Pages;