import React from 'react';
import s from './telephone.module.scss';
import iconPhone from '../../assets/svg/phone.svg';

const Telephone = () => {
    return (
        <a href={"tel:+7 (977) 643-23-93"}>
            <img src={iconPhone} className={s.telIcon}/>
        </a>
    );
};

export default Telephone;