import img1 from '../src/assets/examples/gazebos/image1.jpeg'
import img2 from '../src/assets/examples/gazebos/image2.jpeg'
import img3 from '../src/assets/examples/gazebos/image3.jpeg'
import img4 from '../src/assets/examples/gazebos/image4.jpeg'
import img5 from '../src/assets/examples/gazebos/image5.jpeg'
import img6 from '../src/assets/examples/gazebos/image6.jpeg'
import img7 from '../src/assets/examples/gazebos/image7.jpeg'
import img8 from '../src/assets/examples/gazebos/image8.jpeg'
import img9 from '../src/assets/examples/gazebos/image9.jpeg'
import img10 from '../src/assets/examples/gazebos/image10.jpeg'
import img11 from '../src/assets/examples/gazebos/image11.jpeg'
import img12 from '../src/assets/examples/gazebos/image12.jpeg'
import img13 from '../src/assets/examples/gazebos/image13.jpeg'
import img14 from '../src/assets/examples/gazebos/image14.jpeg'
import img15 from '../src/assets/examples/gazebos/image15.jpeg'

import photo14 from '../src/assets/examples/gazebos/photo6.jpg'
import photo16 from '../src/assets/examples/gazebos/photo8.jpg'
import photo17 from '../src/assets/examples/gazebos/photo9.jpg'
import photo18 from '../src/assets/examples/gazebos/photo10.jpg'
import photo21 from '../src/assets/examples/gazebos/photo13.jpg'
import photo22 from '../src/assets/examples/gazebos/photo14.jpg'
import photo23 from '../src/assets/examples/gazebos/photo15.jpg'
import photo24 from '../src/assets/examples/gazebos/photo16.jpg'
import photo25 from '../src/assets/examples/gazebos/photo17.jpg'
import photo26 from '../src/assets/examples/gazebos/photo18.jpg'
import photo27 from '../src/assets/examples/gazebos/photo19.jpg'
import photo28 from '../src/assets/examples/gazebos/photo20.jpg'
import photo29 from '../src/assets/examples/gazebos/photo21.jpg'
import photo31 from '../src/assets/examples/gazebos/photo22.jpg'
import photo32 from '../src/assets/examples/gazebos/photo23.jpg'
import photo33 from '../src/assets/examples/gazebos/photo24.jpg'
import photo34 from '../src/assets/examples/gazebos/photo25.jpeg'
import photo37 from '../src/assets/examples/gazebos/photo28.jpeg'
import photo39 from '../src/assets/examples/gazebos/photo30.jpeg'
import photo40 from '../src/assets/examples/gazebos/photo31.jpeg'
import photo41 from '../src/assets/examples/gazebos/photo32.jpeg'
import photo42 from '../src/assets/examples/gazebos/photo33.jpeg'
import photo43 from '../src/assets/examples/gazebos/photo34.jpeg'
import photo44 from '../src/assets/examples/gazebos/photo35.jpeg'
import photo45 from '../src/assets/examples/gazebos/photo36.jpeg'
import photo47 from '../src/assets/examples/gazebos/photo38.jpeg'
import photo48 from '../src/assets/examples/gazebos/photo39.jpeg'
import photo50 from '../src/assets/examples/gazebos/photo41.jpeg'
import photo52 from '../src/assets/examples/gazebos/photo43.jpeg'
import photo54 from '../src/assets/examples/gazebos/photo45.jpeg'
import photo55 from '../src/assets/examples/gazebos/photo46.jpeg'
import photo56 from '../src/assets/examples/gazebos/photo47.jpeg'
import photo57 from '../src/assets/examples/gazebos/photo48.jpeg'
import photo58 from '../src/assets/examples/gazebos/photo49.jpeg'
import photo59 from '../src/assets/examples/gazebos/photo50.jpeg'
import photo60 from '../src/assets/examples/gazebos/photo51.jpeg'
import photo61 from '../src/assets/examples/gazebos/photo52.jpeg'
import photo62 from '../src/assets/examples/gazebos/photo53.jpeg'
import photo63 from '../src/assets/examples/gazebos/photo54.jpeg'
import photo64 from '../src/assets/examples/gazebos/photo55.jpeg'
import photo65 from '../src/assets/examples/gazebos/photo56.jpeg'
import photo66 from '../src/assets/examples/gazebos/photo57.jpeg'
import photo67 from '../src/assets/examples/gazebos/photo58.jpeg'


import gazebos_1Img from '../src/assets/photos/gazebosMainPhotos/gazebos1/dachnyeDomikiBesebki_1.jpeg'
import gazebos_1_2Img from '../src/assets/photos/gazebosMainPhotos/gazebos1/dachnyeDomikiBesebki_1_2.jpeg'

import gazebos_2_1Img from '../src/assets/photos/gazebosMainPhotos/gazebos2/2_1.jpeg'
import gazebos_2_2Img from '../src/assets/photos/gazebosMainPhotos/gazebos2/2_2.jpeg'

import gazebos_3Img from '../src/assets/photos/gazebosMainPhotos/gazebos3/dachnyeDomikiBesebki_3.jpg'
import gazebos_3_2Img from '../src/assets/photos/gazebosMainPhotos/gazebos3/dachnyeDomikiBesebki_3_2.webp'
import gazebos_3_3Img from '../src/assets/photos/gazebosMainPhotos/gazebos3/dachnyeDomikiBesebki_3_3.webp'

import gazebos_4Img from '../src/assets/photos/gazebosMainPhotos/gazebos4/dachnyeDomikiBesebki_4.jpg'
import gazebos_4_2Img from '../src/assets/photos/gazebosMainPhotos/gazebos4/dachnyeDomikiBesebki_4_2.jpeg'
import gazebos_4_3Img from '../src/assets/photos/gazebosMainPhotos/gazebos4/dachnyeDomikiBesebki_4_3.jpg'

import gazebos_5Img from '../src/assets/photos/gazebosMainPhotos/gazebos5/dachnyeDomikiBesebki_5.jpeg'

import gazebos_6Img from '../src/assets/photos/gazebosMainPhotos/gazebos6/dachnyeDomikiBesebki_6.jpg'

import gazebos_7_1Img from '../src/assets/photos/gazebosMainPhotos/gazebos7/7_1.webp'

import gazebos_8_1Img from '../src/assets/photos/gazebosMainPhotos/gazebos8/8_1.jpeg'

import gazebos_9Img from '../src/assets/photos/gazebosMainPhotos/gazebos9/dachnyeDomikiBesebki_9.jpg'
import gazebos_9_2Img from '../src/assets/photos/gazebosMainPhotos/gazebos9/dachnyeDomikiBesebki_9_2.jpg'

import gazebos_10Img from '../src/assets/photos/gazebosMainPhotos/gazebos10/dachnyeDomikiBesebki_10.jpeg'
import gazebos_10_2Img from '../src/assets/photos/gazebosMainPhotos/gazebos10/dachnyeDomikiBesebki_10_2.jpg'


import house_1Img from '../src/assets/photos/housesMainPhotos/house1/1_1.jpeg'
import house_2Img from '../src/assets/photos/housesMainPhotos/house2/2_1.png'
import planHouse_1 from '../src/assets/photos/planHouses/house_1.jpg'

import house_2_1Img from '../src/assets/photos/housesMainPhotos/house2/2_2.jpeg'
import planHouse_2 from '../src/assets/photos/planHouses/house_2.jpg'

import house_3Img from '../src/assets/photos/housesMainPhotos/house3/3_1.jpeg'
import house_3_1Img from '../src/assets/photos/housesMainPhotos/house3/3_2.jpeg'
import planHouse_3 from '../src/assets/photos/planHouses/house_3.jpg'

import house_4_1Img from '../src/assets/photos/housesMainPhotos/house4/4_1.jpeg'
import house_4_2Img from '../src/assets/photos/housesMainPhotos/house4/4_2.jpeg'
import planHouse_4 from '../src/assets/photos/planHouses/house_4.jpg'

import house_5Img from '../src/assets/photos/housesMainPhotos/house5/5_1.jpeg'
import house_5_1Img from '../src/assets/photos/housesMainPhotos/house5/5_2.jpeg'
import planHouse_5 from '../src/assets/photos/planHouses/house_5.jpeg'

import house_6Img from '../src/assets/photos/housesMainPhotos/house6/6_1.jpeg'
import planHouse_6 from '../src/assets/photos/planHouses/house_6.jpeg'

import house_7Img from '../src/assets/photos/housesMainPhotos/house7/7_1.jpeg'
import house_7_1Img from '../src/assets/photos/housesMainPhotos/house7/7_2.jpeg'
import planHouse_7 from '../src/assets/photos/planHouses/house_7.jpeg'

import house_8_1Img from '../src/assets/photos/housesMainPhotos/house8/8_1.jpeg'
import house_8_2Img from '../src/assets/photos/housesMainPhotos/house8/8_2.jpeg'
import planHouse_8 from '../src/assets/photos/planHouses/house_8.jpeg'

import house_9_1Img from '../src/assets/photos/housesMainPhotos/house9/9_1.webp'
import planHouse_9 from '../src/assets/photos/planHouses/house_9.jpeg'

import house_10Img from '../src/assets/photos/housesMainPhotos/house10/10_1.jpeg'
import planHouse_10 from '../src/assets/photos/planHouses/house_10.jpeg'

import hozblock_1_1Img from '../src/assets/photos/hozblocksMainPhotos/hozblock1/1_1.jpeg'
import planHozblock_1 from '../src/assets/photos/planHozblocks/hozblock_1.jpeg'

import hozblock_2_1Img from '../src/assets/photos/hozblocksMainPhotos/hozblock2/2_1.jpeg'
import hozblock_2_2Img from '../src/assets/photos/hozblocksMainPhotos/hozblock2/2_2.jpeg'
import planHozblock_2 from '../src/assets/photos/planHozblocks/hozblock_2.jpeg'

import hozblock_3_1Img from '../src/assets/photos/hozblocksMainPhotos/hozblock3/3_1.jpeg'
import hozblock_3_2Img from '../src/assets/photos/hozblocksMainPhotos/hozblock3/3_2.jpeg'
import planHozblock_3 from '../src/assets/photos/planHozblocks/hozblock_3.jpeg'

import hozblock_4_1Img from '../src/assets/photos/hozblocksMainPhotos/hozblock4/4_1.jpeg'
import hozblock_4_2Img from '../src/assets/photos/hozblocksMainPhotos/hozblock4/4_2.jpeg'
import planHozblock_4 from '../src/assets/photos/planHozblocks/hozblock_4.jpeg'

import hozblock_5_1Img from '../src/assets/photos/hozblocksMainPhotos/hozblock5/5_1.jpeg'
import hozblock_5_2Img from '../src/assets/photos/hozblocksMainPhotos/hozblock5/5_2.png'

import hozblock_6_1Img from '../src/assets/photos/hozblocksMainPhotos/hozblock6/6_1.jpeg'
import planHozblock_6 from '../src/assets/photos/planHozblocks/hozblock_6.jpeg'

import hozblock_7_1Img from '../src/assets/photos/hozblocksMainPhotos/hozblock7/7_1.jpg'
import hozblock_7_2Img from '../src/assets/photos/hozblocksMainPhotos/hozblock7/7_2.jpeg'
import planHozblock_7 from '../src/assets/photos/planHozblocks/hozblock_7.jpeg'

import shinglas from './assets/photos/servicesPhotos/shinglas.jpeg'
import window from './assets/photos/servicesPhotos/window.jpg'
import warming from './assets/photos/servicesPhotos/warming.jpeg'
import materials from './assets/photos/servicesPhotos/materials.jpeg'
import treatment from './assets/photos/servicesPhotos/treatment.png'
import foundation from './assets/photos/servicesPhotos/foundation.jpeg'
import delivery from './assets/photos/servicesPhotos/delivery.jpeg'
import assembly from './assets/photos/servicesPhotos/bulding.webp'


export const categories = [
    {
        id: "houses",
        title: 'Садовые дома',
        link: '/catalog/houses',
        mainPhoto: {backgroundImage: `url(${house_1Img})`},
        data: [
            {
                style: {backgroundImage: `url(${house_1Img})`},
                class: "houses",
                id: "house-1",
                name: "Садовый домик №1",
                minPrice: "293 880 ₽",
                square: "30 м²",
                strapping: "Брус 100 х 100",
                dimensions: "5 x 6 м",
                layout: "Комната и веранда",
                description: "Проект садового домика 5х6 метров с одной просторной комнатой и открытой верандой, которая находится под общей крышей здания. Двускатная крыша с покрытием из мягкой черепицы «Шинглас» позволяет избежать мокрого потолка и скопления снега зимой. Домик идеально подходит для устройства небольшой мастерской или летней кухни с естественным освещением за счет функционального окна и двух витражей, а если во время пребывания не планируется приготовление пищи, то комната позволяет вместить в неё две кровати и шкафы для хранения необходимых вещей. Отличный гостевой домик с привлекательным дизайном!",

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "293 880 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "416 280 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "466 872 ₽"},

                plan: planHouse_1,

                images: [
                    {img: house_1Img},
                ]
            },
            {
                style: {backgroundImage: `url(${house_2_1Img})`},
                class: "houses",
                id: "house-2",
                name: "Садовый домик №2",
                minPrice: "215 880 ₽",
                square: "19 м²",
                strapping: "Брус 100 х 100",
                dimensions: "3.8 x 5 м",
                layout: "Комната и веранда",
                description: "Проект дома 3,8 х 5 метров с одной большой комнатой и открытой огороженной перилами из мини-бруса террасой под козырьком, покрытым мягкой черепицей с цветом на Ваш выбор (зеленый, бордовый, коричневый). Для естественного освещения предусмотрены два витража высотой 200 см и одно функциональное окно для вентиляции постройки. Данный садовый домик идеально подойдет комфортного отдыха после работ в саду или для летней кухни, а терраса вместит малогабаритный диван или кресло-качалку для отдыха на свежем воздухе.",

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "215 880 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "323 580 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "406 920 ₽"},

                plan: planHouse_2,

                images: [
                    {img: house_2_1Img},
                    {img: house_2Img},
                ]
            },
            {
                style: {backgroundImage: `url(${house_3Img})`},
                class: "houses",
                id: "house-3",
                name: "Садовый домик №3",
                minPrice: "130 200 ₽",
                square: "9 м²",
                strapping: "Брус 100 х 100",
                dimensions: "3 x 3 м",
                layout: "Комната",
                description: "проект маленького домика 3 х 3 м с вместительной для его габаритов комнатой с функциональным окном и козырьком над дверью. Заменой обычному «давящему» горизонтальному потолку служит архитектурное решение в виде двускатной крыши с высотой около 3 метров в коньке. Этот домик прекрасно впишется на Вашем участке, как игровая комната для детей или летний кабинет, не занимая большую площадь, притягивая своей практичностью для планировки небольшого участка. Домик также можно использовать в качестве хозблока.",

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "130 200 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "227 520 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "270 480 ₽"},

                plan: planHouse_3,

                images: [
                    {img: house_3Img},
                    {img: house_3_1Img},
                ]
            },
            {
                style: {backgroundImage: `url(${house_4_1Img})`},
                class: "houses",
                id: "house-4",
                name: "Садовый домик №4",
                minPrice: "115 020 ₽",
                square: "7.5 м²",
                strapping: "Брус 100 х 100",
                dimensions: "3 x 2.5 м",
                layout: "Комната",
                description: "проект небольшого садового домика 3х2,5 м с одной комнатой, которую можно использовать в качестве просторного светлого кабинета (имеются два функциональных окна) или компактной мастерской. Домик легко вместит шкаф для хранения вещей, а также садовый или строительный инвентарь. Двускатная крыша, покрытая мягкой черепицей «Шинглас», с большими углами наклона подойдет для снежной зимы. Для всесезонного использования домика требуется утепление постройки, также можно установить утепленную  металлическую дверь и водосточную систему ПВХ на два ската кровли. Однако, чаще всего такой домик используют в качестве уютной летней кухни или хозблока.",

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "115 020 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "196 200 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "244 800 ₽"},

                plan: planHouse_4,

                images: [
                    {img: house_4_1Img},
                    {img: house_4_2Img},
                ]
            },
            {
                style: {backgroundImage: `url(${house_5Img})`},
                class: "houses",
                id: "house-5",
                name: "Садовый домик №5",
                minPrice: "228 000 ₽",
                square: "16.5 м²",
                strapping: "Брус 100 х 100",
                dimensions: "5.5 x 3 м",
                layout: "Комната и веранда",
                description: "проект дачного дома 5,5х3 м с большой открытой верандой под общей крышей и одним функциональным окном. Просторная комната позволит укрыться от палящих лучей солнца летом и сохранить тепло зимой при утеплении домика. На открытой веранде удачно расположится столик для приема гостей, а крыша даст возможность устраивать гриль-пати с шашлыками в любую погоду. Снизить теплопотери через окна можно посредством установки пластиковых окон (ПВХ). Вы также можете заменить блочный фундамент на свайный в качестве дополнительной опции.",

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "228 000 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "335 520 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "395 520 ₽"},

                plan: planHouse_5,

                images: [
                    {img: house_5Img},
                    {img: house_5_1Img},
                ]
            },
            {
                style: {backgroundImage: `url(${house_6Img})`},
                class: "houses",
                id: "house-6",
                name: "Садовый домик №6",
                minPrice: "237 600 ₽",
                square: "16 м²",
                strapping: "Брус 100 х 100",
                dimensions: "4 x 4 м",
                layout: "Комната",
                description: "проект дачного домика 4х4 метра с большой комнатой, которую можно использовать в качестве зоны отдыха для уединения на природе. Три функциональных окна позволяют обеспечить естественным освещением и хорошей вентиляцией весь домик. Часть жилой зоны идеально подойдет под летнюю кухню, часть домика вместит всю необходимую мебель – стол, стулья, кровать. По желанию помещение можно разделить перегородками.",

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "237 600 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "334 200 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "409 680 ₽"},

                plan: planHouse_6,

                images: [
                    {img: house_6Img},
                ]
            },
            {
                style: {backgroundImage: `url(${house_7Img})`},
                class: "houses",
                id: "house-7",
                name: "Садовый домик №7",
                minPrice: "403 800 ₽",
                square: "36 м²",
                strapping: "Брус 100 х 100",
                dimensions: "6 x 6 м",
                layout: "Две комнаты и веранда",
                description: "проект садового дома 6 х 6 метров с двумя просторными помещениями, которые можно использовать в качестве хозблоков для хранения строительных материалов, садового инвентаря или для устройства небольшой светлой мастерской. Крепкие полы из досок сечением 35 мм выдержат инвентарь и материалы любой тяжести. В домике имеются четыре функциональных окна для доступа к естественному освещению и вентиляции. Большая веранда с ограждением из бруса, поддерживаемая архитектурными опорами, легко вместит стол и стулья для приятного времяпровождения. По сути это проект 2 в 1: в нем есть и закрытые жилые комнаты, и открытая терраса для отдыха шириной 2 метра.",

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "403 800 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "561 120 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "647 760 ₽"},

                plan: planHouse_7,

                images: [
                    {img: house_7Img},
                    {img: house_7_1Img},
                ]
            },
            {
                style: {backgroundImage: `url(${house_8_1Img})`},
                class: "houses",
                id: "house-8",
                name: "Садовый домик №8",
                minPrice: "313 080 ₽",
                square: "24 м²",
                strapping: "Брус 100 х 100",
                dimensions: "6 x 4 м",
                layout: "Комната и веранда",
                description: "проект дачного домика 6х4 метров с большой комнатой, которую можно использовать в качестве гостевой спальни или игровой светлой комнаты для детей. В домике есть два функциональных окна для обеспечения вентиляции и естественного освещения. Большая терраса с общей двускатной крышей пристройки вместит стол и стулья для большой компании, позволит устраивать шашлыки и гриль-вечеринки в любую погоду. Для всесезонного использования дачного домика рекомендуется утеплить пол и кровлю, а для предотвращения теплопотерь через окна – установить окна ПВХ. Но главное достоинство этого проекта - это сочетание закрытой жилой части с открытой беседкой для отдыха.",

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "313 080 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "401 760 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "478 080 ₽"},

                plan: planHouse_8,

                images: [
                    {img: house_8_1Img},
                    {img: house_8_2Img},
                ]
            },
            {
                style: {backgroundImage: `url(${house_9_1Img})`},
                class: "houses",
                id: "house-9",
                name: "Садовый домик №9",
                minPrice: "238 800 ₽",
                square: "20 м²",
                strapping: "Брус 100 х 100",
                dimensions: "5 x 4 м",
                layout: "Две комнаты и крыльцо",
                description: "проект садового домика 5х4 метра с компактным уютным крыльцом под общей двускатной крышей постройки, закрытой кухней и большой комнатой, площадь которой можно использовать под спальню, гостевую комнату или небольшую мастерскую. В проекте предусмотрены три функциональных окна. На крыльцо приятно выйти в дождливую погоду, подышать свежим воздухом, насладиться видом собственного сада. Данный домик станет отличным местом для загородного отдыха, а его небольшая площадь позволит рационально использовать земельный участок.",

                plan: planHouse_9,

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "238 800 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "350 880 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "427 800 ₽"},

                images: [
                    {img: house_9_1Img},
                ]
            },
            {
                style: {backgroundImage: `url(${house_10Img})`},
                class: "houses",
                id: "house-10",
                name: "Садовый домик №10",
                minPrice: "288 000 ₽",
                square: "20.25 м²",
                strapping: "Брус 100 х 100",
                dimensions: "4.5 x 4.5 м",
                layout: "Две комнаты и коридор",
                description: "проект домика 4,5х4,5 метров с небольшим коридором, уютной кухней и большой комнатой. Два больших функциональных окна обеспечивают попадание естественного света и вентиляцию. Комната может служить не только вместительной мастерской, в которую поместятся шкаф для хранения инструментов, но и вместит в себя зону отдыха или место для приема друзей. Кухня скрыта от посторонних глаз, доступ только через жилую зону. Для круглогодичного использования домика можно утеплить полы и крышу, установить утепленную металлическую дверь.",

                plan: planHouse_10,

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "288 000 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "366 900 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "442 800 ₽"},

                images: [
                    {img: house_10Img},
                ]
            },
        ]
    },

    {
        id: "gazebos", title: 'Беседки', link: '/catalog/gazebos', mainPhoto: {backgroundImage: `url(${gazebos_4Img})`},
        data: [
            {
                style: {backgroundImage: `url(${gazebos_3Img})`},
                class: "gazebos",
                id: "gazebos-1",
                name: "Открытая беседка №1",
                minPrice: "93 600 ₽",
                square: "9 м²",
                strapping: "Брус 100 х 100",
                dimensions: "3 x 3 м",
                layout: "Открытое помещение",
                description: "Проект компактной беседки 3х3 метра из мини-бруса. Планировка и оформление участка предполагает не только озеленение территории и красивый сад, но и оформление функциональных зон. Открытая беседка позволит разместить стол и стулья для пикников с друзьями на свежем воздухе. Даже в дождливую погоду крыша, покрытая мягкой черепицей «Шинглас», защитит от осадков и мероприятие состоится. Полы из шпунтовой доски сечением 35 мм выдержат даже самые сильные нагрузки. Данная беседка – прекрасное место для отдыха на природе в жаркие летние дни.",

                styleMissingPlan: {display: "none"},

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "93 600 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "114 000 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "121 200 ₽"},
                images: [
                    {img: gazebos_3Img},
                    {img: gazebos_3_2Img},
                    {img: gazebos_3_3Img}
                ]
            },
            {
                style: {backgroundImage: `url(${gazebos_2_1Img})`},
                class: "gazebos",
                id: "gazebos-2",
                name: "Закрытая беседка №2",
                minPrice: "155 640 ₽",
                square: "9 м²",
                strapping: "Брус 100 х 100",
                dimensions: "3 x 3 м",
                layout: "Закрытое помещение",
                description: "Проект закрытой беседки 3х3 метра с одним входом. Три функциональных окна позволяют проветривать помещение и любоваться природой, не выходя на улицу. Закрытая беседка позволит полностью защититься от капризов погоды и даст возможность выбрать обстановку и декор – от мебели с электроникой до ковров и картин. Конструкция беседки защитит от комаров и других насекомых, которые могут помешать отдыху. Чтобы использовать постройку круглый год, возможно утепление крыши и пола, а также установка металлической двери.",

                styleMissingPlan: {display: "none"},

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "155 640 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "179 520 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "223 080 ₽"},

                images: [
                    {img: gazebos_2_1Img},
                    {img: gazebos_2_2Img},
                ]
            },
            {
                style: {backgroundImage: `url(${gazebos_1Img})`},
                class: "gazebos",
                id: "gazebos-3",
                name: "Открытая беседка №3",
                minPrice: "119 400 ₽",
                square: "9 м²",
                strapping: "Брус 100 х 100",
                dimensions: "3 x 3 м",
                layout: "Открытое помещение",
                description: "Классическая открытая беседка для летнего отдыха. Площадь - 9 м2, один вход. Это идеальный вариант для тех, кто любит наслаждаться свежим воздухом и быть как можно ближе к природе. При этом вы будете защищены от осадков благодаря массивной мягкой кровле, которая выступает за границы перил и стен.",

                styleMissingPlan: {display: "none"},

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "119 400 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "148 000 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "172 600 ₽"},


                plan: planHouse_1,


                images: [
                    {img: gazebos_1Img},
                    {img: gazebos_1_2Img},
                ]
            },

            {
                style: {backgroundImage: `url(${gazebos_4Img})`},
                class: "gazebos",
                id: "gazebos-4",
                name: "Открытая беседка №4",
                minPrice: "101 160 ₽",
                square: "12 м²",
                strapping: "Брус 100 х 100",
                dimensions: "3 x 4 м",
                layout: "Открытое помещение",
                description: "Проект открытой беседки 3х4 метра с одним входом. Основу постройки составляют четыре деревянных столба, выполненных из мини-бруса и компактная двускатная крыша. Площадь этой малой архитектурной формы вместит столик и стулья или лавки для заслуженного отдыха небольшой компании. Крыша беседки защитит от палящего солнца или дождливой летней погоды. Также можно украсить строение шпалерами с неприхотливым вьющимся растением – через некоторое время всю постройку оплетет яркая зелень, превратив её в прекрасное украшение любого дачного участка.",

                styleMissingPlan: {display: "none"},

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "101 160 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "151 080 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "165 480 ₽"},

                images: [
                    {img: gazebos_4Img},
                    {img: gazebos_4_2Img},
                    {img: gazebos_4_3Img},
                ]
            },
            {
                style: {backgroundImage: `url(${gazebos_5Img})`},
                class: "gazebos",
                id: "gazebos-5",
                name: "Полуоткрытая беседка №5",
                minPrice: "154 800 ₽",
                square: "17.5 м²",
                strapping: "Брус 100 х 100",
                dimensions: "3,5 x 5 м",
                layout: "Полуоткрытое помещение",
                description: "проект полуоткрытой беседки 3,5х5 метров с одним входом. На оконные проемы можно установить комплект шпалер с неприхотливым вьющимся растением или плотные шторы для защиты от ветра прохладным вечером. Беседка вместит в себя мангал для пикника с шашлыками, подойдет для устройства зоны барбекю, позволит насладиться уединением в комфортной зоне отдыха или провести время в узком семейном кругу за чашечкой чая. Двускатная крыша покрыта мягкой черепицей «Шинглас» и защищает от непогоды.",

                styleMissingPlan: {display: "none"},

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "154 800 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "249 600 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "289 200 ₽"},

                images: [
                    {img: gazebos_5Img},
                ]
            },
            {
                style: {backgroundImage: `url(${gazebos_6Img})`},
                class: "gazebos",
                id: "gazebos-6",
                name: "Полуоткрытая беседка №6",
                minPrice: "227 640 ₽",
                square: "24 м²",
                strapping: "Брус 100 х 100",
                dimensions: "6 х 4 м",
                layout: "Полуоткрытое помещение",
                description: "Проект полуоткрытой беседки 6х4 метра с одной глухой стеной и одним входом. Двускатная крыша с покрытием из мягкой черепицы «Шинглас» позволит с комфортом разместить большую компанию за обеденным столом с лавками даже в дождливую погоду. Площадь беседки вместит гриль-зону для пикника. Для защиты от ветра или косого дождя можно повесить плотные шторы, которые защитят вас от ветра солнечных лучей. Также можно организовать площадку для танцев – крепкие полы из шпунтовой доски сечением 35 мм выдерживают огромные нагрузки.",

                styleMissingPlan: {display: "none"},

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "227 640 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "293 520 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "338 040 ₽"},

                images: [
                    {img: gazebos_6Img},
                ]
            },
            {
                style: {backgroundImage: `url(${gazebos_7_1Img})`},
                class: "gazebos",
                id: "gazebos-7",
                name: "Полуоткрытая беседка №7",
                minPrice: "160 800 ₽",
                square: "16 м²",
                strapping: "Брус 100 х 100",
                dimensions: "4 х 4 м",
                layout: "Полуоткрытое помещение",
                description: "Проект полуоткрытый беседки 4х4 метра с ограждением из бруса. Крыша с покрытием из мягкой черепицы позволит собраться с друзьями теплым летним вечером за шашлыками или чаем даже в дождливую погоду. Для защиты от косого дождя и ветра в межсезонье отличным решением послужат плотные шторы. Проемы можно задекорировать шпалерами. Постройка подойдет по стилю к любому основному дому, придаст шарм и уют садовому участку. Данную беседку часто выбирают любители загородного отдыха.",

                styleMissingPlan: {display: "none"},

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "160 800 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "223 080 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "262 800 ₽"},

                images: [
                    {img: gazebos_7_1Img},
                ]
            },
            {
                style: {backgroundImage: `url(${gazebos_8_1Img})`},
                class: "gazebos",
                id: "gazebos-8",
                name: "Открытая беседка №8",
                minPrice: "145 080 ₽",
                square: "16 м²",
                strapping: "Брус 100 х 100",
                dimensions: "4 х 4 м",
                layout: "Открытое помещение",
                description: "Проект открытой беседки 4х4 метра с одним входом. Беседка, огороженная брусом и четырьмя опорами по периметру постройки, станет функциональным украшением любого участка. Двускатная крыша укроет от непогоды. Площадь постройки вместит мангал для приготовления вкусных шашлыков и компактную обеденную зону в кругу близких людей. Также беседку полюбят маленькие дети, если обустроить часть строения под игровую зону. Данный проект популярен среди людей, ценящих пространство и красоту, хотят разнообразить садовый участок уютным уголком для отдыха.",

                styleMissingPlan: {display: "none"},

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "145 080 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "195 780 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "235 140 ₽"},

                images: [
                    {img: gazebos_8_1Img},
                ]
            },
            {
                style: {backgroundImage: `url(${gazebos_9Img})`},
                class: "gazebos",
                id: "gazebos-9",
                name: "Открытая беседка №9",
                minPrice: "199 500 ₽",
                square: "25 м²",
                strapping: "Брус 100 х 100",
                dimensions: "5 х 5 м",
                layout: "Открытое помещение",
                description: "Проект открытой беседки 5х5 метров с одним входом. Шесть деревянных опор напоминают стиль «прованс», данный стиль можно подчеркнуть с помощью светлой мебели и комплектом шпалер. Просторная беседка вместит диван, чайный столик или может стать прекрасной обеденной зоной. От летнего дождя укроет двускатная крыша с покрытием из мягкой черепицы «Шинглас». Данный проект украсит любой садовый участок, подарит незабываемый отдых на свежем воздухе с родными и близкими.",

                styleMissingPlan: {display: "none"},

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "199 500 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "259 350₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "299 250₽"},
                /*Цены не менял*/
                images: [
                    {img: gazebos_9Img},
                    {img: gazebos_9_2Img},
                ]
            },
            {
                style: {backgroundImage: `url(${gazebos_10Img})`},
                class: "gazebos",
                id: "gazebos-10",
                name: "Полуоткрытая беседка №10",
                minPrice: "216 600 ₽",
                square: "28,09 м²",
                strapping: "Брус 100 х 100",
                dimensions: "5,3 x 5,3 м",
                layout: "Полуоткрытое помещение",
                description: "Проект полуоткрытой беседки 5,3х5,3 метра с двумя выходами на улицу. Двускатная крыша надежно защищает от осадков и капризов погоды. Площадь позволяет вместить летнюю кухню со столовой зоной для приятного времяпровождения в кругу семьи. Проемы беседки подходят для установки комплекта шпалер с вьющимися зелеными растениями. От ветра смогут защитить плотные шторы. Домик идеально подойдет как для уединения на природе с журналом, так и для посиделок в большой компании на свежем воздухе за чашечкой кофе.",

                styleMissingPlan: {display: "none"},
                /*Цены не менял*/
                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "216 600 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "282 150 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "325 850 ₽"},

                images: [
                    {img: gazebos_10Img},
                    {img: gazebos_10_2Img},
                ]
            },

        ]
    },

    {
        id: "hozblocks",
        title: 'Хозблоки',
        link: '/catalog/hozblocks',
        mainPhoto: {backgroundImage: `url(${hozblock_2_1Img})`},
        data: [
            {
                style: {backgroundImage: `url(${hozblock_1_1Img})`},
                class: "hozblocks",
                id: "hozblock-1",
                name: "Хозблок №1",
                minPrice: "118 400 ₽",
                square: "5 м²",
                strapping: "Брус 100 х 100",
                dimensions: "2.5 x 2 м",
                layout: "Две комнаты",
                description: "Проект хозблока 2,5 х 2 метра представляет собой компактную постройку, состоящей из двух помещений. Пространство постройки можно использовать в качестве душа и санузла. Предусмотрен отдельный вход для каждого помещения, благодаря чему не возникает очереди, ведь каждая комната может быть использована для удовлетворения разных нужд одновременно. Также постройка подойдет для кладовой с двумя отсеками, в первом может храниться садовый и дачный инструмент, во втором – саженцы и рассада. Правильная форма и небольшие размеры хозблока позволят без проблем разместить его в любом удобном месте на дачном участке.",

                plan: planHozblock_1,

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "118 400 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "156 400 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "172 200 ₽"},

                images: [
                    {img: hozblock_1_1Img},
                ]
            },
            {
                style: {backgroundImage: `url(${hozblock_2_1Img})`},
                class: "hozblocks",
                id: "hozblock-2",
                name: "Хозблок №2",
                minPrice: "199 500 ₽",
                square: "13.5 м²",
                strapping: "Брус 100 х 100",
                dimensions: "4.5 x 3 м",
                layout: "Комната",
                description: "Проект хозблока 4,5 x 3 метра представляет собой компактную постройку, состоящей из одного помещения. Правильная форма и небольшие размеры хозблока позволят без проблем разместить его в любом удобном месте на дачном участке.",

                plan: planHozblock_2,

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "199 500 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "259 350 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "299 250 ₽"},
                /*Цены не менял*/
                images: [
                    {img: hozblock_2_1Img},
                    {img: hozblock_2_2Img},
                ]
            },
            {
                style: {backgroundImage: `url(${hozblock_3_1Img})`},
                class: "hozblocks",
                id: "hozblock-3",
                name: "Хозблок №3",
                minPrice: "115 200 ₽",
                square: "6 м²",
                strapping: "Брус 100 х 100",
                dimensions: "3 x 2 м",
                layout: "Комната",
                description: "Проект хозблока 3 х 2 метра является компактной постройкой правильной прямоугольной формы с одним входом. Строение имеет одно помещение, площадь которого можно оборудовать под хранение лопат, веников, ведер, иного хозяйственного или строительного инвентаря. Помещение вместит полки, стеллажи, инструментальные ящики, вешалки для одежды. Односкатная крыша позволит без особого труда убрать с кровли снег или листья. Благодаря небольшому размеру данного хозблока он без труда поместится на любом участке и украсит его.",

                plan: planHozblock_3,

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "120 900 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "172 800 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "204 000 ₽"},

                images: [
                    {img: hozblock_3_1Img},
                    {img: hozblock_3_2Img},
                ]
            },
            {
                style: {backgroundImage: `url(${hozblock_4_1Img})`},
                class: "hozblocks",
                id: "hozblock-4",
                name: "Хозблок №4",
                minPrice: "230 160 ₽",
                square: "15 м²",
                strapping: "Брус 100 х 100",
                dimensions: "5 x 3 м",
                layout: "Две комнаты",
                description: "Проект хозблока 5х3 метра является компактным строением с двумя выходами. Постройка имеет два функциональных помещения. Помещение побольше можно использовать в качестве склада – через широкий дверной проем пройдет объемная мебель, крупногабаритный инвентарь. Крепкие полы из досок сечением 35 мм выдержат самые тяжелые стеллажи со всем необходимым. Второе помещение можно использовать как место для хранения необходимого оборудования. Материал двускатной крыши – мягкая черепица «Шинглас». Этот практичный хозблок украсит любой садовый участок и освободит пространство в основном доме.",

                plan: planHozblock_4,

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "230 160 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "327 480 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "377 520 ₽"},

                images: [
                    {img: hozblock_4_1Img},
                    {img: hozblock_4_2Img},
                ]
            },
            {
                style: {backgroundImage: `url(${hozblock_5_1Img})`},
                class: "hozblocks",
                id: "hozblock-5",
                name: "Хозблок №5",
                minPrice: "71 250 ₽",
                square: "6 м²",
                strapping: "Брус 100 х 100",
                dimensions: "3 x 2 м",
                layout: "Открытый дровник",
                description: "Проект открытого хозблока 3х2 метра, представлен в виде строения с небольшим пандусом. Данную постройку можно эффективно использовать в качестве дровника – ограждение из резного бруса обеспечит свободную циркуляцию воздуха, а значит предотвратит появление конденсата и последующего гниения древесины, а крыша защитит от прямых осадков. Односкатная кровля – прекрасное решение для легкой и непринужденной уборки снега и листьев с крыши. Покрытие кровли – мягкая черепица «Шинглас». Такой практичный дровник станет стильной постройкой и впишется в ландшафтный дизайн любого участка.",

                styleMissingPlan: {display: "none"},

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "71 250 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "93 100 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "107 350 ₽"},
                /*Цены не менял*/
                images: [
                    {img: hozblock_5_1Img},
                    {img: hozblock_5_2Img},
                ]
            },
            {
                style: {backgroundImage: `url(${hozblock_6_1Img})`},
                class: "hozblocks",
                id: "hozblock-6",
                name: "Хозблок №6",
                minPrice: "134 800 ₽",
                square: "8 м²",
                strapping: "Брус 100 х 100",
                dimensions: "4 x 2 м",
                layout: "Комната",
                description: "Проект хозблока 4х2 метра, функциональная постройка с одним входом. Строение состоит из хозяйственного помещения, которое позволяет разместить на своей площади стеллажи с необходимыми вещами, полки, ящики под инструмент, вешалки для одежды. Крепкий пол из досок 35 мм выдержит самые тяжелые предметы из инвентаря и мебель. Функциональное окно обеспечит должное проветривание и доступ света в помещение. Материал крыши – покрытие из мягкой черепицы «Шинглас». Данная постройка будет служить практичным украшением любого дачного участка.",

                plan: planHozblock_6,

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "134 800 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "180 000 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "218 880 ₽"},

                images: [
                    {img: hozblock_6_1Img},
                ]
            },
            {
                style: {backgroundImage: `url(${hozblock_7_1Img})`},
                class: "hozblocks",
                id: "hozblock-7",
                name: "Хозблок №7",
                minPrice: "253 920 ₽",
                square: "19 м²",
                strapping: "Брус 100 х 100",
                dimensions: "5 x 3.8 м",
                layout: "Две комнаты и веранда",
                description: "Хозблок 5х3,8 метра является строением 2 в 1 с вместительной террасой. Комнаты обособлены друг от друга перегородкой, имеют два отдельных входа. Данные помещения можно оборудовать под вместительные бытовки для хранения газонокосилок, ведер, вил, лопат и другого хозяйственного инвентаря. Также есть возможность устроить небольшую мастерскую с инструментальными ящиками, при желании сделать гостевую спальню или временное жилье во время постройки основного дома. Площадь террасы вместит небольшой обеденный столик со стульями для отдыха на свежем воздухе.",

                plan: planHozblock_7,

                firstMaterial: {title: "Профилированная доска: 135 х 45 мм", price: "259 560 ₽"},
                secondMaterial: {title: "Профилированный мини-брус: 135 х 60 мм", price: "357 000 ₽"},
                thirdMaterial: {title: "Клееный брус: 135 х 75 мм", price: "413 280 ₽"},

                images: [
                    {img: hozblock_7_1Img},
                    {img: hozblock_7_2Img},
                ]
            },
        ]
    }
]


export const servises = [
    {
        id: "assembly",
        title: 'Бесплатная сборка',
        link: '/services/assembly',
        mainPhoto: {backgroundImage: `url(${assembly})`}
    },
    {
        id: "materials",
        title: 'Материалы строительства',
        link: '/services/materials',
        mainPhoto: {backgroundImage: `url(${materials})`}
    },
    {
        id: "roof",
        title: 'Гибкая черепица Shinglas',
        link: '/services/roof',
        mainPhoto: {backgroundImage: `url(${shinglas})`}
    },
    {
        id: "foundation",
        title: 'Фундамент',
        link: '/services/foundation',
        mainPhoto: {backgroundImage: `url(${foundation})`}
    },
    {
        id: "delivery", title: 'Доставка', link: '/services/delivery', mainPhoto: {backgroundImage: `url(${delivery})`}
    },
    {
        id: "window",
        title: 'Установка пластиковых окон',
        link: '/services/window',
        mainPhoto: {backgroundImage: `url(${window})`}
    },
    {
        id: "warming",
        title: 'Утепление строения',
        link: '/services/warming',
        mainPhoto: {backgroundImage: `url(${warming})`}
    },
    {
        id: "treatment",
        title: 'Обработка антисептиком',
        link: '/services/treatment',
        mainPhoto: {backgroundImage: `url(${treatment})`}
    },

]

export const examplesGazebosPhotos = [
    {src: img1, alt: "img1"},
    {src: img2, alt: "img1"},
    {src: photo57, alt: "img57"},
    {src: photo58, alt: "img58"},
    {src: photo59, alt: "img59"},
    {src: photo60, alt: "img60"},
    {src: photo61, alt: "img61"},
    {src: photo62, alt: "img62"},
    {src: photo63, alt: "img63"},
    {src: photo64, alt: "img64"},
    {src: photo65, alt: "img65"},
    {src: photo66, alt: "img66"},
    {src: img3, alt: "img1"},
    {src: img4, alt: "img1"},
    {src: img5, alt: "img1"},
    {src: img6, alt: "img1"},
    {src: img7, alt: "img1"},
    {src: img8, alt: "img1"},
    {src: img9, alt: "img1"},
    {src: img10, alt: "img1"},
    {src: img11, alt: "img1"},
    {src: img12, alt: "img1"},
    {src: img13, alt: "img1"},
    {src: img14, alt: "img1"},
    {src: img15, alt: "img1"},
    {src: photo34, alt: "photo12"},
    {src: photo37, alt: "photo12"},
    {src: photo39, alt: "photo12"},
    {src: photo40, alt: "photo12"},
    {src: photo41, alt: "photo12"},
    {src: photo42, alt: "photo12"},
    {src: photo43, alt: "photo12"},
    {src: photo44, alt: "photo12"},
    {src: photo45, alt: "photo12"},
    {src: photo47, alt: "photo12"},
    {src: photo48, alt: "photo12"},
    {src: photo50, alt: "photo12"},
    {src: photo52, alt: "photo12"},
    {src: photo67, alt: "img67"},
    {src: photo54, alt: "photo12"},
    {src: photo55, alt: "photo12"},
    {src: photo56, alt: "photo12"},
    {src: photo14, alt: "photo14"},
    {src: photo16, alt: "photo16"},
    {src: photo17, alt: "photo17"},
    {src: photo18, alt: "photo18"},
    {src: photo21, alt: "photo21"},
    {src: photo22, alt: "photo22"},
    {src: photo23, alt: "photo23"},
    {src: photo24, alt: "photo24"},
    {src: photo25, alt: "photo25"},
    {src: photo26, alt: "photo26"},
    {src: photo27, alt: "photo27"},
    {src: photo28, alt: "photo28"},
    {src: photo29, alt: "photo29"},
    {src: photo31, alt: "photo29"},
    {src: photo32, alt: "photo29"},
    {src: photo33, alt: "photo29"},
]